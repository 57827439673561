import React, { Component } from 'react';
import { Navigate, Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { VscFeedback } from "react-icons/vsc";
import axios from 'axios';

class Intro extends Component {

    settings = this.props.Settings;
    language = this.props.Language;

    state = {
        menus: [],
        menuLoad: false,
        navigate: false,
        isLoading: false,
        showLang: false,
        showMenu: true,
    }
    
    async componentDidMount() {

        const {data} = await axios.get("https://manage.caviarmenu.com/api/menu/swisslandgenev");
        this.setState({ menus: data }, () => {
            this.setState({menuLoad: true});
        });
          
        // setTimeout(() => {
        //     this.setState({ logo: true });
        // }, 2000);

    }

    handleChange = lang => {
        this.props.handleLangaugeSubmit(lang);
        this.setState({ showLang: true, showMenu: false});
    }

    handleMenuChange = menu => {
        this.props.handleMenuSubmit(menu);
        this.setState({ navigate : true});
    }

    render() {

        const { isLoading } = this.state;

        return (
            <>
            
                { this.state.navigate && <Navigate to="/category" replace={true} /> }

                {
                    isLoading ? 
                    <div className='intro'>
                            <div className="head">
                                <SkeletonTheme  baseColor="#FFFFFF12" highlightColor="#6b6150" >
                                    <Skeleton height={150} width={150} circle={true} containerClassName="flex-1" />
                                </SkeletonTheme>
                            </div>

                            <SkeletonTheme  baseColor="#FFFFFF12" highlightColor="#6b6150" >
                                <Skeleton height={200} containerClassName="flex-1" />
                            </SkeletonTheme>

                            <SkeletonTheme  baseColor="#FFFFFF12" highlightColor="#6b6150" >
                                <Skeleton height={200} containerClassName="flex-1" />
                            </SkeletonTheme>
                        
                    </div>
                    : 
                    <div className='intro' style={ { backgroundImage :`url(${this.settings.introCover})`, backgroundSize: 'cover', backgroundOrigin: 'bottom' } } >
                    <div className="head">
                        {/* <div className='send-feedback'>
                            <Link to={"/feedback"}>
                                <VscFeedback /> <span>Feedback</span> 
                            </Link>
                        </div> */}
                        <img src={this.settings.logo} alt="" />
                        {/* <h3>{this.settings.name}</h3> */}
                    </div>
                    <div className={`form`}>
                        <button className={`${this.state.showLang? 'intro-none': `intro-hidden-lang-0`}`} onClick={() => this.handleChange('krd')} > کوردی </button>
                        <button className={`${this.state.showLang? 'intro-none': `intro-hidden-lang-1`}`} onClick={() => this.handleChange('en')}> English </button>
                        <button className={`${this.state.showLang? 'intro-none': `intro-hidden-lang-2`}`} onClick={() => this.handleChange('ar')}> عربي </button>
                        {/* <div className='langBtnContainer'>
                            <div>
                                
                            </div>
                            <div>
                                
                            </div>
                        </div> */}
                    </div>

                    {
                        
                        this.state.menuLoad ? 
                            <div className={`form ${this.state.showMenu? 'intro-none': ''}`} >
                                {
                                    this.state.menus.map( (menu, index) => 
                                        <button key={index} onClick={() => 
                                        {
                                        
                                        this.handleMenuChange(index)
                                       
                                    }
                                            
                                    } className={`${ (this.state.showMenu ? 'intro-none' : `intro-hidden-menu-${index}`) }`}
                                        
                                        
                                        
                                         >
                                            {
                                                this.props.Language == "en" ? menu.name_eng
                                                : this.props.Language == "krd" ? menu.name_krd
                                                : menu.name_ar
                                            } 
                                            
                                            
                                        </button> )
                                }
                            </div> : ''
                    }

                    

                    <div className="info">

                        <div className="social">

                            { 
                                this.settings.instgram 
                                &&
                                <a href={this.settings.instgram}><i className="fa-brands fa-instagram"></i></a>
                            }

                            { 
                                this.settings.snapchat
                                &&
                                <a href={this.settings.snapchat}><i className="fa-brands fa-snapchat"></i></a>
                            }

                            { 
                                this.settings.facebook
                                &&
                                <a href={this.settings.facebook}><i className="fa-brands fa-square-facebook"></i></a>
                            }

                            { 
                                this.settings.titok
                                &&
                                <a href={this.settings.titok}><i className="fa-brands fa-tiktok"></i></a>
                            }

                        </div>
                    </div>


                    <div className="footer">
                        Powered By <a href='https://www.instagram.com/caviar.menu/' className='caviar'>Caviar Menu</a>
                    </div>


                </div>

                }

               
            </>
        );
    }
}

export default Intro;